export type DiscoverSortInfo = {
  value: 'lastUpdated' | 'dateCreated' | 'date' | 'geo:20' | 'geo:35' | 'geo:50';
  text: string;
}

export const discoverSorts: Array<DiscoverSortInfo> = [
  {
    value: 'lastUpdated',
    text: 'Last Updated'
  },
  {
    value: 'dateCreated',
    text: 'Newest'
  },
  {
    value: 'date',
    text: 'Upcoming'
  },
  {
    value: 'geo:20',
    text: 'Within 20 miles'
  },
  {
    value: 'geo:35',
    text: 'Within 35 miles'
  },
  {
    value: 'geo:50',
    text: 'Within 50 miles'
  },
]
