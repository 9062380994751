

























































































































































































import Vue from "vue";
import { Component, Prop } from 'vue-property-decorator'
import { discoverSorts } from '../model/DiscoverSort'
import { getIconWhite } from '../model/EventIcons'

@Component
export default class EventsDisplay extends Vue {

  @Prop({ required: true })
  value!: Array<Record<string, unknown>>

  @Prop({ required: false })
  sortKey!: string

  @Prop({ default: true })
  readonly showSort

  @Prop({ default: true })
  readonly enableGeoQueries

  private get sortOptions () {
    if (this.enableGeoQueries) {
      return discoverSorts
    } else {
      return discoverSorts.filter(sort => !sort.value.startsWith('geo:'))
    }
  }

  private getEventTypeDisplay (type) {
    switch (type) {
      case 'MOBILE_RELEASE':
        return 'Mobile Release'
      case 'ONLINE_RELEASE':
        return 'Online Release'
      case 'MERCH':
        return 'Merch Drop'
      case 'FESTIVAL':
        return 'Festival'
      case 'TAP_TAKEOVER':
        return 'Tap Takeover'
      case 'BREWERY_RELEASE':
        return 'Brewery Release'
      case 'NEWS':
        return 'News'
      default:
        return 'Event'
    }
  }

  private eventIcon (type) {
    return getIconWhite(type)
  }

  private onIntersect () {
    this.$emit('loadMore')
  }

  private setSort (val) {
    this.$emit('setSort', val)
  }

  private eventCardColor (event) {
    if (event.organization.style?.eventCardColor != null) {
      return event.organization.style.eventCardColor
    } else {
      return 'primary'
    }
  }

  private eventTextColor (event) {
    if (event.organization.style?.eventTextColor != null) {
      return event.organization.style.eventTextColor
    } else {
      return '#ffffff'
    }
  }

  private formatDate(date) {
    var d = new Date(date.seconds * 1000); 
    var mm = d.getMonth() + 1
    var dd = d.getDate()
    var yy = String(d.getFullYear())

    return mm + "/" + dd + "/" + yy.substring(yy.length - 2, yy.length);
  }

  getUpdatedStr(event) {
    if (event.dateCreated.seconds !== event.lastUpdated.seconds) {
      return "updated " + this.formatDate(event.lastUpdated)
    }
    
    return "added " + this.formatDate(event.dateCreated)
  }

  orgLocationStr(event) {
    if (event.locations[0] != null) {
      const loc = event.locations[0]
      return  loc.city + ", " + loc.region
    }

    return ""
  }

  private eventIsSoldOut (event) {
    let hasBeers = event.beers != null && event.beers.length > 0
    let hasMerch = event.merch != null && event.merch.length > 0

    if (hasBeers && hasMerch) {
      let allBeersSoldOut = event.beers.every(function (beer) {
        return beer.isSoldOut
      });

      let allMerchSoldOut = event.merch.every(function (merch) {
        return merch.isSoldOut
      });

      return allMerchSoldOut && allBeersSoldOut
    }
    else if (hasBeers) {
      return event.beers.every(function (beer) {
        return beer.isSoldOut
      });
    }
    else if (hasMerch) {
      return event.merch.every(function (merch) {
        return merch.isSoldOut
      });
    }

    return false
  }

}
